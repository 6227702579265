import {compress, decompress} from 'brotli-compress'
import {basicSetup, EditorView} from "codemirror";
import {javascript} from "@codemirror/lang-javascript";
import {html} from "@codemirror/lang-html";
import {css} from "@codemirror/lang-css";
import {Tooltip, showTooltip} from "@codemirror/view";
import {StateField, Transaction} from "@codemirror/state";


/**
 * @param {string} base64
 * @returns {Uint8Array}
 */
function base64ToBytes(base64) {
    const binString = atob(base64);
    return Uint8Array.from(binString, (m) => m.codePointAt(0));
}

function bytesToBase64(bytes) {
    const binString = Array.from(bytes, (byte) =>
        String.fromCodePoint(byte),
    ).join("");
    return btoa(binString);
}

/**
 * @param {string} text
 * @returns {Promise<string>}
 */
async function compressText(text) {
    const compressed = await compress((new TextEncoder()).encode(text));
    return bytesToBase64(compressed);
}

/**
 *
 * @param {string} text
 * @returns {Promise<string>}
 */
async function decompressText(text) {
    const inputBytes = base64ToBytes(text);
    let bytes = await decompress(inputBytes);
    return (new TextDecoder()).decode(bytes);
}

async function loadContents() {
    let data;
    try {
        data = JSON.parse(await decompressText(location.hash.slice(1)));
    } catch {
        data = {};
    }
    return {
        html: data.html ?? '',
        css: data.css ?? '',
        js: data.js ?? '',
    };
}


function createEditor(id, contents, plugins, onUpdate) {
    let updateExtension = EditorView.updateListener.of((update) => {
        if (!update.docChanged) {
            return;
        }
        onUpdate(id, update.state.doc.toString());
    });

    return new EditorView({
        doc: contents,
        extensions: [
            basicSetup,
            EditorView.lineWrapping,
            updateExtension,
            ...plugins,
        ],
        parent: document.querySelector(`#${id} div`)
    });
}

/** @type {number|undefined} */
let scheduledUrlUpdate;

(() => {
    let iframe = document.querySelector('iframe');
    let jsEditor;
    let sources = {html: '', css: '', js: ''};
    /** @type {Error|undefined} */
    let javascriptError;

    function runPlayground() {
        requestAnimationFrame(() => {
            let source = `<!DOCTYPE html>
<html lang="en">
    <head>
        <title>Preview</title>
        <style>${sources.css}</style>
    </head>
    <body>
    ${sources.html}
    </body>
</html>`
            iframe.contentDocument.write(source);
            iframe.contentDocument.close();
            let previousError = javascriptError;
            try {
                iframe.contentWindow.eval(sources.js);
                javascriptError = undefined;
            } catch(error) {
                javascriptError = error;
            }
            if (previousError !== javascriptError) {
                jsEditor.dispatch({
                    changes: {from: 0, insert: ""}
                });
            }
        });
    }

    const javascriptErrorPlugin = StateField.define({
        create(state){
            if (!javascriptError) { return []; }
            return [{
                pos: state.doc.line(javascriptError.lineNumber).from + javascriptError.columnNumber,
                strictSide: true,
                arrow: true,
                create: () => {
                    let dom = document.createElement("div")
                    dom.className = "cm-tooltip-cursor cm-tooltip-error"
                    dom.textContent = javascriptError.toString();
                    return {dom}
                }
            }];
        },

        update(tooltips, tr) {
            return this.create(tr.state);
        },

        provide: f => showTooltip.computeN([f], state => state.field(f))
    });

    loadContents().then((initialContents) => {
        sources = initialContents;

        async function updateUrl() {
            let url = new URL(location.href);
            url.hash = await compressText(JSON.stringify(sources));
            history.replaceState(null, '', url.toString());
        }

        function onUpdate(id, text) {
            sources[id] = text;
            if (scheduledUrlUpdate) {
                clearTimeout(scheduledUrlUpdate);
            }
            scheduledUrlUpdate = setTimeout(() => {
                scheduledUrlUpdate = undefined;
                updateUrl().then();
                runPlayground();
            }, 350);
        }

        createEditor('html', initialContents.html, [html()], onUpdate);
        createEditor('css', initialContents.css, [css()], onUpdate);
        jsEditor = createEditor('js', initialContents.js, [javascript(), javascriptErrorPlugin], onUpdate);

        // Initial rendering.
        runPlayground();

        const queryParameters = new URLSearchParams(location.search);
        if (queryParameters.get('results')) {
            document.body.classList.add('results-only');
            document.getElementById('edit-button').addEventListener('click', () => {
                console.log('cliiiick');
                document.body.classList.remove('results-only');
            });
        }
    });
})();
